import { FileFragment, ImageFragment, useGetConfigurationQuery } from "@gql-schema";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "@main-zone/components/navigation";
import { resizerImageUrl } from "@uxf/core/utils/resizer";
import { Loader } from "@uxf/ui/loader";
import React, { FC, Fragment } from "react";

interface Props {
    children: React.ReactNode;
    image?: FileFragment | ImageFragment | null;
}

const Header: FC<Props> = (props) => {
    const { data: configData } = useGetConfigurationQuery();
    if (!configData) {
        return <Loader />;
    }
    const configuration = configData.configuration;

    return (
        <div className="relative overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl">
                <div className="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
                    <svg
                        className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 text-white lg:block"
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                    >
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>

                    <Popover>
                        <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
                            <nav
                                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                                aria-label="Global"
                            >
                                <div className="flex shrink-0 grow items-center lg:grow-0">
                                    <div className="flex w-full items-center justify-between md:w-auto">
                                        <a href="/">
                                            <span className="sr-only">{configuration.h1Title}</span>
                                            <img
                                                className="h-16 w-24 sm:h-20"
                                                src={
                                                    resizerImageUrl(configuration.logo ?? null, 858, 720, {}) ??
                                                    undefined
                                                }
                                                alt={`${configuration.h1Title} - logo`}
                                            />
                                        </a>
                                        <div className="-mr-2 flex items-center md:hidden">
                                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Open main menu</span>
                                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:ml-10 md:block md:space-x-8 md:pr-4">
                                    {navigation.main.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="font-medium text-gray-500 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </nav>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                className="absolute inset-x-0 top-0 z-10 origin-top-right p-2 transition md:hidden"
                            >
                                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black/5">
                                    <div className="flex items-center justify-between px-5 pt-4">
                                        <div>
                                            <img
                                                className="h-16 w-20"
                                                src={
                                                    resizerImageUrl(configuration.logo ?? null, 858, 720, {}) ??
                                                    undefined
                                                }
                                                alt={`${configuration.h1Title} - logo`}
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Zavřít menu</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="space-y-1 px-2 pb-3 pt-2">
                                        {navigation.main.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                    <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                        <div className="sm:text-center lg:text-left">{props.children}</div>
                    </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                {props.image ? (
                    <img
                        alt={`${configuration.h1Title} - ${configuration.h1Subtitle}`}
                        src={resizerImageUrl(props.image, 858, 720, {}) ?? undefined}
                    />
                ) : (
                    <img
                        alt={`${configuration.h1Title} - ${configuration.h1Subtitle}`}
                        src={
                            configuration.headingImage
                                ? resizerImageUrl(configuration.headingImage, 858, 720, {}) ?? undefined
                                : undefined
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default Header;
