export interface Navigation {
    main: {
        name: string;
        href: string;
    }[];
    footer: {
        name: string;
        href: string;
    }[];
}

export const navigation: Navigation = {
    main: [
        { name: "Aktuality", href: "/aktuality" },
        { name: "Partneři", href: "/partneri" },
        { name: "Akce", href: "/akce" },
        { name: "Kontakty", href: "/kontakty" },
        { name: "Pro členy", href: "/pro-cleny" },
    ],
    footer: [
        { name: "Aktuality", href: "/aktuality" },
        { name: "Partneři", href: "/partneri" },
        { name: "Akce", href: "/akce" },
        { name: "Kontakty", href: "/kontakty" },
        { name: "Pro členy", href: "/pro-cleny" },
        { name: "Přihlášení", href: "/prihlaseni" },
    ],
};
